div.box-qna {
	background-image: url(../img/bg004.jpg);
	background-position: center center;
	background-size: cover;
	position: relative;
	color: var(--color-text-brightondark);
}

div.box-qna-overlay {
	background-color: #000000;
	opacity: 0.3;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
}

div.box-qna h2,
div.box-qna h3 {
	color: var(--color-text-brightondark);
}

div.box-qna div.q h3 {
	padding: 10px 0;
}
div.box-qna div.q h3:hover {
	color: var(--color-brown);
}
div.box-qna div.q h3 svg {
	width: 18px;
	height: 18px;
	float: right;
	margin-top: 7px;
}
div.box-qna div.q h3 svg.icon-plus {
	display: none;
}
div.box-qna input[type=radio]:checked ~ h3 svg.icon-plus {
	display: block;
}
div.box-qna input[type=radio]:checked ~ h3 svg.icon-minus {
	display: none;
}




div.box-qna div.q {
	border-bottom: 1px solid #FFFFFF26;
}

div.box-qna div.q:last-child {
	border-bottom: none;
}

div.box-qna label {
	display: block;
	cursor: pointer;
	margin-right: 50px;
}

div.box-qna input[type=radio] {
	display: none;
}

div.box-qna p.a {
	display: none;
	padding-right: 50px;
	text-align: justify;
}
.qna-a {
	text-align: justify;
}

div.box-qna input[type=radio]:checked ~ h3 {
	color: var(--color-brown);
}

div.box-qna input[type=radio]:checked ~ p.a {
	display: block;
}