div.divider-line {
	width: 48px;
	height: 3px;
	background-color: var(--color-brown);
	margin-left: auto;
	margin-right: 0;
}

div.divider-line.h3 {
	height: 2px;
	background-color: var(--color-brown-dark);
}

div.divider-space.h1 {
	height: var(--spacer-size-h1);
}

div.divider-space.h2 {
	height: var(--spacer-size-h2);
}

div.divider-space.h3 {
	height: var(--spacer-size-h3);
}
