div.box-bg-orit-portrate {
	background-image: url(../img/orit-portrate.jpg);
	background-position: top center;
	background-repeat: no-repeat;
	background-size: cover;
}
.box-about-page p {
	text-align: justify;
}

@media screen and (max-width: 900px) {
	div.box-2s:has(> div.box-bg-orit-portrate) {
		flex-wrap: wrap;
	}
	div.box-bg-orit-portrate {
		aspect-ratio: 4/3;
	}
}