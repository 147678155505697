:root {
	--spacer-size-h1: 10px;
	--spacer-size-h2: 22px;
	--spacer-size-h3: 10px;
	--color-brown: #d3a651;
	--color-brown-dark: #C2873F;
	--color-brown-darker: #8d5f28;
	--color-light-gray: #F6F6F7;
	--color-text: #63666c;
	--color-text-headers: #272a33;
	--color-text-brightondark: #ffffff;
	--color-background-dark: #272a33;
	--color-icon: #3F3E3E;
	/* */
	--color-inputs-text: #63666c;
	--color-inputs-background: #ffffff;
	--color-inputs-placeholder: #a9aaad;
	--color-inputs-border: #e5e5e7;
	--color-inputs-border-focus: #bfbfbf;
}

html, body {
	direction: rtl;
	color: var(--color-text);
	background-color: var(--color-light-gray);
	font-family: Noto, sans-serif;
	margin: 0;
	padding: 0;
	scroll-behavior: smooth;
}

h1, h2, h3, h4, h5, h6 {
	margin-top: 0;
	color: var(--color-text-headers);
	font-weight: 700;
	font-style: normal;
	text-rendering: optimizeLegibility;
}

h1 {
	font-size: 36px;
	line-height: 48px;
	font-weight: 700;
	margin: 0;
}

h2 {
	margin-bottom: 12px;
	font-size: 42px;
}

h3 {
	font-size: 20px;
	line-height: 30px;
	font-weight: 600;
	margin-bottom: 0;
}

a {
	color: var(--color-brown);
	text-decoration: none;
	cursor: pointer;
}

a:hover {
	text-decoration: underline;
}

input[type=text], input[type=email], input[type=tel], textarea, button {
	font-family: Rubik, sans-serif;
	font-size: 16px;
	background-color: var(--color-inputs-background);
	width: 100%;
	margin-bottom: 0;
	color: var(--color-inputs-text);
	border: 1px solid var(--color-inputs-border);
	padding: 0 22px;
	height: 50px;
	text-shadow: none;
	box-shadow: none;
	box-sizing: border-box;
	margin: 0;
	transition: all 0.3s ease;
	direction: rtl;
}

textarea {
	padding: 15px 22px;
	min-height: 100px;
	overflow: auto;
	vertical-align: top;
}

input:focus, textarea:focus {
	border-color: var(--color-inputs-border-focus);
	outline: 0;
	-webkit-box-shadow: none;
	box-shadow: none;
}

::placeholder {
	color: var(--color-inputs-placeholder);
	opacity: 1;
}

:-ms-input-placeholder {
	color: var(--color-inputs-placeholder);
}



p {
	margin: 0 0 20px;
	font-size: 17px;
	line-height: 29px;
}



ul {
	margin: 0;
}

li {
	margin: 0 0 20px 0;
	font-size: 17px;
	line-height: 29px;
}


i.icon-big {
    font-size: 60px;
    color: var(--color-icon);
}

/*
ul.ul-check {
	list-style: none;
	margin: 0;
	padding: 0;
}

ul.ul-check li {
	font-weight: bold;
	display: flex;
	align-items: center;
}

ul.ul-check li svg {
	width: 26px;
	height: 26px;
	fill: var(--color-brown-dark);
	margin-left: 10px;
}
*/



div.main {
    background-image: url(img/bg006.jpg);
    background-position: bottom left;
    background-repeat: no-repeat;
    background-size: cover;
	padding-top: 70px;
	padding-bottom: 70px;
}
