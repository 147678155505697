.box-QnaPage h1 {
    text-align: center;
    margin-bottom: 10px;;
}


.box-QnaPage .qna-item {
    margin-bottom: 30px;
}
.box-QnaPage .qna-item .qna-q {
    margin-bottom: 10px;
    cursor: pointer;
}
.box-QnaPage .qna-item h3 {
    font-size: 22px;
}
.box-QnaPage .qna-item svg {
    width: 20px;
    height: 20px;
    float: right;
    margin-left: 10px;
    margin-top: 5px;
}

.box-QnaPage .qna-a {
    padding-right: 30px;
}