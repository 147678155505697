div.box-bg-001 {
	background-image: url(../img/bg001.jpg);
	background-position: 79% 50%;
	background-repeat: no-repeat;
	background-size: cover;
}

.occupations-topic {
	text-align: justify;
}
