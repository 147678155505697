.burger-icon {
	height: 20px;
	cursor: pointer;
	position: relative;
	transition: all 0.3s ease;
}
.burger-icon .bar {
    width: 25px;
    height: 2px;
    background-color: #fff;
    transition: all 0.3s ease;
}
.burger-icon .bar:not(:first-child) {
    margin-top: 6px;
}
#burger-icon-trigger:checked ~ .burger-icon .bar:first-child {
	transform: rotate(-45deg);
	margin-top: 10px;
}
#burger-icon-trigger:checked ~ .burger-icon .bar:nth-child(3) {
	margin-top: -10px;
	transform: rotate(45deg);
}
#burger-icon-trigger:checked ~ .burger-icon .bar:nth-child(2) {
	opacity: 0;
}
#burger-icon-trigger {
	display: none;
}