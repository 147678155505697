div.header-desktop {
	background-color: transparent;
	padding: 10px 0 0 0;
	position: fixed;
	top: 0;
	width: 100%;
	border-bottom: 1px solid #FFFFFF1A;
	transition: background 0.3s ease;
	z-index: 1000;
}

html:not([data-scroll='0']) div.header-desktop {
	background-color: var(--color-background-dark);
}

div.header-desktop div.logo {
	padding-bottom: 10px;
}
div.header-desktop div.logo img {
	/* width: 150px; */
	transition: all 0.3s;
}
div.header-desktop div.call-us {
	color: var(--color-text-brightondark);
	font-size: 24px;
	white-space: nowrap;
	font-weight: 600;
	margin-top: 15px;
}
div.header-desktop div.call-us a {
	color: var(--color-text-brightondark);
}
div.header-desktop div.call-us div.label {
	color: var(--color-brown);
	font-size: 12px;
	font-weight: 700;
	letter-spacing: 1.3px;
}




@media screen and (max-width: 1000px) {
	div .header-desktop div.logo img {
		width: auto;
		height: 50px;
	}

	div.header-desktop div.call-us {
		font-size: 20px;
	}
}