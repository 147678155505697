ul.menu {
	margin: 0;
	list-style: none;
	display: flex;
	gap: 30px;
	margin-top: 25px;
}
ul.menu li {
	color: var(--color-text-brightondark);
	font-size: 14px;
	font-weight: 600;
	text-transform: uppercase;
	position: relative;
	cursor: pointer;
	margin-bottom: 0;
	padding-bottom: 40px;
    display: inline-block;
}
ul.menu li:before {
	background-color: var(--color-brown);
	height: 2px;
	left: 0;
	content: "";
	width: 0;
	position: absolute;
	right: 0;
	margin-top: 20px;
	transition: width 0.5s cubic-bezier(0.35, 0.7, 0.35, 1) 0s;
}
ul.menu li.active:before,
ul.menu li:hover:before {
	width: 100%;
}

ul.menu li a {
	color: var(--color-text-brightondark);
}
ul.menu li a:hover {
	text-decoration: none;
}
ul.menu div.sub-menu {
	position: fixed;
	top: 80px;
	padding: 10px 0;
	background-color: var(--color-background-dark);
}

ul.menu div.sub-menu a {
	font-size: 14px;
    line-height: 36px;
    padding: 0 24px;
    transition: all 0.3s ease;
	font-weight: 600;
    letter-spacing: 0.2px;
	position: relative;
    display: none;
}
ul.menu div.sub-menu a:hover {
	color: var(--color-brown-dark);
}
ul.menu li:hover div.sub-menu a {
	display: block;	
}

div.mobile-menu-icon {
	display: none;
	align-self: center;
}


@media screen and (max-width: 1000px) {
	ul.menu {
		gap: 20px;
	}
	ul.menu li {
		padding-bottom: 25px;
	}
}
@media screen and (max-width: 900px) {
	ul.menu {
		gap: 17px;
		padding-right: 20px;
	}
}

@media screen and (max-width: 880px) {
	ul.menu,
	div.call-us {
		display: none;
	}
	div.mobile-menu-icon {
		display: block;
	}
}








div.header-mobile .mobile-menu-overly {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	z-index: 99999;
	background-color: #000000cc;
	display: none;
}
div.header-mobile .mobile-menu-overly label {
	width: 100%;
	height: 100%;
	display: block;
}
div.header-mobile div.mobile-menu {
	display: block;
	position: fixed;
	top: 0;
	bottom: 0;
	left: -360px; /* plus padding */
	z-index: 99999;
	width: 250px;
	max-width: calc(100% - 80px);
	padding: 10px 30px 50px 30px;
	overflow-x: hidden;
	overflow-y: auto;
	background-color: #272a33;
	transition: all 0.3s ease;
}

div.header-mobile a.mobile-menu-logo {
	display: block;
	text-align: center;
    margin-bottom: 25px;
}
div.header-mobile a.mobile-menu-logo img {
	width: 70%;
	margin: 10px auto 10px auto;
}


div.header-mobile ul {
	list-style: none;
    margin: 0;
    padding: 0;
}
div.header-mobile ul.mobile-menu-links li {
	border-top: 1px solid rgba(255, 255, 255, 0.07);
    margin: 0;
    padding: 0;
    width: 100%;
}
div.header-mobile ul.mobile-menu-links li a {
	color: var(--color-inputs-border-focus);
    font-size: 15px;
	font-weight: bold;
    line-height: 45px;
	display: block;
	transition: all 0.3s ease;
}
div.header-mobile ul.mobile-menu-links ul li a {
	padding-right: 30px;
}
div.header-mobile ul.mobile-menu-links li a:hover {
	color: var(--color-brown-dark);
	text-decoration: none;
}


.mobile-menu-trigger {
	display: none;
}
#mobile-menu-trigger:checked ~ div.mobile-menu {
    left: 0;
}
#mobile-menu-trigger:checked ~ div.mobile-menu-overly {
	display: block;
}
