div.header-home {
	background-color: #3d3e43;
	background-image: url(../img/bg007.jpg);
	background-position: center center;
	background-size: cover;
	padding-top: 15px;
	z-index: 10;
	position: relative;
	color: var(--color-text-brightondark);
}
div.header-home div.header-home-main {
	display: flex; 
	justify-content: center; 
	padding: 90px 0 0 0;
}

div.header-home div.orit-home-pic {
	animation: 1s ease-out 0s 1 slideInFromLeft;
	align-self: flex-end;
}
div.header-home div.orit-home-pic img {
	width: 600px;
	transition: all 0.3s ease;
	aspect-ratio: 600/823;
}

div.header-home h1 {
	color: var(--color-text-brightondark);
	width: 100%;
	font-size: 48px;
	line-height: 62px;
	animation: 0.5s ease-out 0s 1 slideInFromRight;
	transition: all 0.3s ease;
}

div.header-home div.main-content {
	width: 620px;
	margin: 30px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	animation: 0.7s ease-out 0s 1 slideInFromRight;
	transition: all 0.3s ease;
}

div.header-home div.devided-line {
	border-right: 2px solid var(--color-brown-dark);
	padding: 0 30px 0 0;
	margin-bottom: 30px;
	text-align: justify;
}

div.header-home div.devided-line p {
	font-size: 19px;
	margin-bottom: 30px;
}

div.header-home div.devided-line p:last-child {
	margin-bottom: 4px;
}

div.header-home button {
	color: var(--color-icon);
	width: max-content;
	border-color: var(--color-text-headers);
	animation: 1s ease-out 0s 1 slideInFromRight;
	cursor: pointer;
	font-weight:bold;
}





@keyframes slideInFromLeft {
	0% {	transform: translateX(-50px); }
	100% {	transform: translateX(0); }
}
@keyframes slideInFromRight {
	0% {	transform: translateX(50px); }
	100% {	transform: translateX(0); }
}





@media screen and (max-width: 1400px) {
	div.header-home div.orit-home-pic {
		animation: none;
	}
	div.header-home h1 {
		animation: none;
	}
	div.header-home div.main-content {
		animation: none;
	}
	div.header-home button {
		animation: none;
	}

	div.header-home h1 {
		font-size: 40px;
		line-height: 53px;
	}
	div.header-home div.orit-home-pic img {
		width: 500px;
	}
}
@media screen and (max-width: 1200px) {
	div.header-home h1 {
		font-size: 36px;
		line-height: 48px;
	}
	div.header-home div.orit-home-pic img {
		width: 400px;
	}
}
@media screen and (max-width: 1100px) {
	div.header-home div.devided-line p {
		font-size: 17px;
		line-height: 27px;
		margin-bottom: 20px;
	}
}
@media screen and (max-width: 1000px) {
	div.header-home h1 {
		font-size: 33px;
		line-height: 45px;
	}
	div.header-home div.devided-line p {
		font-size: 16px;
		line-height: 22px;
		margin-bottom: 19px;
	}
	div.header-home div.orit-home-pic img {
		width: 350px;
	}
}
@media screen and (max-width: 920px) {
	div.header-home h1 {
		font-size: 30px;
		line-height: 40px;
	}

	div.header-home div.devided-line p {
		font-size: 16px;
		line-height: 22px;
		margin-bottom: 19px;
	}

	div.header-home div.orit-home-pic img {
		width: 300px;
	}
}
@media screen and (max-width: 850px) {
	div.header-home h1 {
		font-size: 28px;
		line-height: 36px;
	}
}
@media screen and (max-width: 800px) {
	/* div.header-home div.main-content h1 span:last-child {
		display: none;
	} */
	div.header-home div.main-content p:last-child {
		display: none;
	}
}
@media screen and (max-width: 700px) {
	div.header-home div.orit-home-pic img {
		width: 400px;
		max-width: 90%;
	}

	div.header-home div.header-home-main {
		flex-wrap: wrap;
	}

	div.header-home div.orit-home-pic {
		border-bottom: 1px solid #C2873F;
		width: 100%;
		text-align: center;
	}

	div.header-home div.devided-line {
		padding: 0;
		border-right-width: 0;
	}
	div.header-home div.main-content h1 span:last-child {
		display: block;
	}

	div.header-home div.main-content p:last-child {
		display: block;
	}
}





div.bubble-strip {
	background-color: var(--color-brown-dark);
	height: 65px;
}