div.box-AboutOffice {
	z-index: 10;
	position: relative;
	margin-right: auto;
	margin-left: auto;
	text-align: justify;
}

div.box-AboutOffice li a {
	color: var(--color-text);
}
div.box-AboutOffice h1 {
	text-align: center;
	font-size: 42px;
	margin-bottom: 14px;
}

div.box-AboutOffice p {
	color: #63666c;
	margin-left: auto;
	margin-right: auto;
}