div.box-contact-us-wide {
	background-image: url(../img/bg003.jpg);
	background-position: bottom center;
	background-size: cover;
	position: relative;
	padding-bottom: 70px;
}

div.box-contact-us-wide-overlay {
	background-color: #ffffff;
	opacity: 0.9;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
}

div.box-contact-us-wide-main {
	max-width: 1600px;
	margin-left: auto;
	margin-right: auto;
}

div.box-contact-us {
	container-type: inline-size;
}

div.box-contact-us div.form-grid {
	display: grid;
	grid-template-columns: 50% 50%;
	gap: 20px;
	position: relative;
}



div.box-contact-us-wide-main span.social {
	display: flex;
	align-items: flex-end;
}
div.box-contact-us-wide-main span.social a {
	display: inline-block;
	width: 44px;
	height: 44px;
	line-height: 46px;
	border-radius: 22px 22px 22px 22px;
	font-size: 22px;
	background-color: var(--color-inputs-placeholder);
	background-color: #a9aaad44;
	display: inline-flex;
	justify-content: center;
	align-content: center;
	margin: 15px 0 0 10px;
	transition: all 0.6s ease;
}
div.box-contact-us-wide-main span.social a svg {
	width: 16px;
	fill: var(--color-text);
}
div.box-contact-us-wide-main h3 a svg {
	width: 16px;
	fill: var(--color-brown-dark);
}
div.box-contact-us-wide-main span.social a:hover {
	background-color: var(--color-brown);
}
div.box-contact-us-wide-main span.social a:hover svg {
	fill: var(--color-text);
}







@container (width < 600px) {
	div.box-contact-us div.form-grid {
		display: flex;
		flex-direction: column;
	}
}

@media screen and (max-width: 800px) {
	div.box-contact-us-wide-main {
		flex-wrap: wrap;
		flex-direction: column;
	}
}