div.box-bg-002 {
	background-image: url(../img/bg002.jpg);
	background-position: 77% 0px;
	background-repeat: no-repeat;
	background-size: cover;
}

@media screen and (max-width: 1024px) {
	div.box-bg-002 {
		background-position: 64% 0px;
	}
}

@media screen and (max-width: 850px) {
	div.box-2s:has(> div.box-bg-002) {
		flex-direction: column-reverse;
	}
}
