/*
input:invalid,
textarea:invalid {
    border-color: red;
}
*/

span.FormValidationMessage,
span.FormValidationMessage {
    content: 'שדה חובה';
    display: block;
    color: red;
    font-size: 14px;
}

