div.box-occupations {
	z-index: 10;
	position: relative;
	margin-right: 30px;
	margin-left: 30px;
}

div.box-occupations div.spacer {
	height: 90px;
}

div.box-occupations h1 {
	text-align: center;
	font-size: 42px;
	margin-bottom: 14px;
}

div.box-occupations p {
	max-width: 750px;
	text-align: center;
	color: #63666c;
	margin-left: auto;
	margin-right: auto;
}