div.box {
	margin: 0 0 50px 0;
}

div.box-shadow {
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}

div.box-2s {
	display: flex;
}

div.box-col50 {
	width: 50%;
}

div.box-middle {
	max-width: 1200px;
	margin-right: auto;
	margin-left: auto;
}

div.box-padding {
	position: relative;
}

div.box-middle div.box-padding {
	padding: 5% 10% 5% 10%;
}

div.box-fullwidth div.box-padding {
	padding: 5% 18% 5% 18%;
}


@media screen and (max-width: 1024px) {
	div.box-col50 {
		width: 100%;
	}

	div.box-bg {
		width: 35%;
	}
}
@media screen and (max-width: 768px) {
	div.box-fullwidth {
		flex-wrap: wrap;
	}
	div.box-fullwidth > div {
		width:100%;
	}
	div.box-fullwidth div.box-padding {
		padding: 3% 10%;
	}
}

@media screen and (max-width: 1024px) {
	div.box-bg-001 {
		background-position: 77% 0px;
	}
}

@media screen and (max-width: 850px) {
	div.box-2s {
		flex-wrap: wrap;
	}
	div.box-bg {
		width: 100%;
		height: 300px;
	}
}

div.box-fullwidth div.form-grid .form-message {
	grid-column: 1/3;
}
	