div.box-meet {
	background-image: url(../img/bg003.jpg);
	position: relative;
	container-type: inline-size;
}

div.box-meet-overlay {
	background-color: #ffffff;
	opacity: 0.9;
	height: 100%;
	width: 100%;
	top: 0;
	left: 0;
	position: absolute;
}

div.box-meet div.form-grid {
	display: grid;
	grid-template-columns: 50% 50%;
	gap: 20px;
}

@container (width < 600px) {
	div.box-meet div.form-grid {
		display: flex;
		flex-direction: column;
	}
}


div.box-meet div.form-grid .form-message {
    grid-column: 1/3;
}
