ul.ul-check {
	/* list-style: none; */
	margin: 0;
	padding: 0;
	margin-right: 20px;
}

ul.ul-check li {
	/* display: flex; */
	align-items: center;
	margin: 0 0 10px 0;
}
ul.ul-check li:last-child {
	margin: 0 0 20px 0;
}
ul.ul-check li svg {
	width: 24px;
	height: 24px;
	fill: var(--color-brown-dark);
	margin-left: 10px;
}


ul.ul-check li::marker {
	color: var(--color-brown-dark);
	size: 12px;
}
