div.box-about-home {
	padding-top: 70px;
}

div.box-about-home h2 {
	text-align: center;
}
div.box-about-home p {
	text-align: justify;
}

div.box-bg-orit-about {
	background-image: url(../img/orit-about.jpg);
	background-position: center center;
	background-repeat: no-repeat;
	background-size: cover;
}

div.box-about-home-desc {
	padding-right: 50px;
	padding-left: 50px;
}

@media screen and (max-width: 800px) {
	div .box-2s:has(> div.box-bg-orit-about) {
		flex-wrap: wrap;
	}

	div.box-bg-orit-about {
		aspect-ratio: 7/3;
	}

	div.box-about-home-desc {
		padding-top: 30px;
	}
}

@media screen and (max-width: 550px) {
	div.box-about-home h2 {
		font-size: 36px;
	}
}
@media screen and (max-width: 450px) {
	div.box-about-home h2 {
		font-size: 32px;
	}
}
