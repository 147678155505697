div.footer {
	background-color: #2e313a;
	background-image: url(../img/bg005.jpg);
	background-size: cover;
	padding: 50px 10% 20px 10%;
}
div.footer div.first-line {
	display: flex;
	justify-content: space-between;
	margin-bottom: 70px;
	align-items: flex-end;
}


div.footer div.links {
	display: flex;
	justify-content: space-between;
    flex-wrap: wrap;
}
div.footer div.links > div {
	width: 220px;
}
div.footer div.links a {
	color: var(--color-inputs-border-focus);
	transition: all 0.6s ease;
}
div.footer div.links a:hover {
	color: var(--color-brown-dark);
	text-decoration: none;
}
div.footer div.contact-links li {
	font-size: 18px;
	font-weight: 500;
	line-height: 24px;
	display: flex;
	align-items: center;
}
div.footer div.contact-links li svg {
	width: 24px;
	height: 24px;
	stroke-width: 1;
	fill: #C2873F;
	margin-left: 10px;
}
div.footer h3 {
	color: white;
    margin-top: 30px;
}
div.footer ul {
	list-style: none;
	margin: 0;
	padding: 0;
}
div.footer li {
	color: var(--color-inputs-border-focus);
	margin: 0 0 15px 0;
}
div.footer span.social {
	display: flex;
	align-items: flex-end;
	margin-top: 20px;
}
div.footer span.social a {
	display: inline-block;
	width: 44px;
	height: 44px;
	line-height: 46px;
	border-radius: 22px 22px 22px 22px;
	font-size: 22px;
	color: var(--color-inputs-border-focus);
	background-color: #FFFFFF1A;
	display: inline-flex;
	justify-content: center;
	align-content: center;
	margin-right: 10px;
	transition: all 0.6s ease;
}
div.footer span.social a:hover {
	background-color: var(--color-brown);
}
div.footer span.social a:hover svg {
	fill: var(--color-text);
}
div.footer span.social a svg {
	width: 16px;
	fill: var(--color-inputs-border-focus);
}
div.footer div.copy {
	display: flex;
	justify-content: space-around;
	margin-top: 70px;
	padding: 25px 0 5px 0;
	color: var(--color-inputs-border-focus);
	border-top: 1px solid #FFFFFF1A;
}


div.footer .footer-logo {
	height: 75px;
}
@media screen and (max-width: 580px) {
	div.footer .footer-logo {
		height: 60px;
	}
}
@media screen and (max-width: 470px) {
	div.footer .footer-logo {
		height: 45px;
	}
}





a.scroll-up {
	text-decoration: none;
	transition: all 0.3s ease;
	position: fixed !important;
	right: 30px;
	bottom: 0;
	display: block;
	font-size: 20px;
	text-align: center;
	color: #fff;
	background-color: #d3a651;
	width: 50px;
	height: 50px;
	line-height: 50px;
	visibility: hidden;
	opacity: 0;
	z-index: 99999;
	cursor: pointer;
	overflow: hidden;
}

html:not([data-scroll='0']) a.scroll-up {
	bottom: 30px;
	opacity: 1;
	visibility: visible;
}




a.whatsapp-float {
	text-decoration: none;
	transition: all 0.3s ease;
	position: fixed !important;
	right: 20px;
	bottom: 20px;
	display: block;
	font-size: 20px;
	text-align: center;
	color: #fff;
	background-color: #24cc63;
	width: 50px;
	height: 50px;
	line-height: 50px;
	visibility: hidden;
	opacity: 0;
	z-index: 99999;
	cursor: pointer;
	overflow: hidden;
	border-radius: 50%;
	display: flex;
    justify-content: center;
    align-items: center;
}
a.call-float {
	text-decoration: none;
	transition: all 0.3s ease;
	position: fixed !important;
	left: 20px;
	bottom: 20px;
	display: block;
	font-size: 20px;
	text-align: center;
	color: #fff;
	background-color: #3fa0e7;
	width: 50px;
	height: 50px;
	line-height: 50px;
	visibility: hidden;
	opacity: 0;
	z-index: 99999;
	cursor: pointer;
	overflow: hidden;
	border-radius: 50%;
	display: flex;
    justify-content: center;
    align-items: center;
}

@media screen and (max-width: 800px) {
	a.whatsapp-float {
		opacity: 1;
		visibility: visible;
	}
	a.call-float {
		opacity: 1;
		visibility: visible;
	}
}