div.form-container-out {
    position: relative;
    top:-100px;
    box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.1);
    background-color: white;
    margin-right: auto;
    margin-left: auto;
    max-width: 1000px;
}
div.form-container-in {
    position: relative;
    padding: 43px 60px 45px 60px;
    container-type: inline-size;
}

div.form-container-in h3 {
    text-align: center;
    margin-bottom: 30px;
}



div.form-container-in div.form-grid {
    display: grid;
    grid-template-columns: 30% 30% 30%;
    gap: 20px;
}

div.form-container-in div.form-grid .form-subject {
    grid-column: 1/4;
}
div.form-container-in div.form-grid .form-message {
    grid-column: 1/4;
}
div.form-container-in div.form-grid .form-message textarea {
    height: 170px;
}
div.form-container-in div.form-grid .form-submit {
    grid-column: 2/3;
}



div.form-container-in input[type=text],
div.form-container-in input[type=email], 
div.form-container-in input[type=tel], 
div.form-container-in textarea {
    background-color: #fbfbfb;
}



@container (width < 700px) {
    div.form-container-in div.form-grid {
        display: flex;
        flex-direction: column;
    }
}




.contact-us-overlay {
    background-image: url(../img/bg003.jpg);
    background-position: bottom center;
    opacity: 0.1;
    mix-blend-mode: multiply;

    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    position: absolute;
}

.contact-us-boxs {
    position: relative;
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 100px;
}
.contact-us-box {
    padding: 45px 40px 50px 40px;
    background-color: #FFFFFF;
    box-shadow: 10px 10px 40px 0px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 230px;
}


.contact-us-box i.iconsmind {
	font-size: 50px;
    color: var(--color-brown);
    display: inline-block;
    margin-bottom: 20px;
}
.contact-us-box h3 {
    margin-bottom: 10px;
}
div.contact-us-box a {
    color: var(--color-text);
    transition: all 0.6s ease;
}
div.contact-us-box a:hover {
    color: var(--color-brown);
}

@media screen and (max-width: 1000px) {
    .contact-us-boxs {
        flex-direction: column;
        align-items: center;
        width: 500px;
    }
    .contact-us-box {
        width: 500px;
    }
}

@media screen and (max-width: 650px) {
    .contact-us-box {
        padding-left: 0;
        padding-right: 0;
    }
}

@media screen and (max-width: 530px) {
    .contact-us-box {
        width: 100%;
    }
    .contact-us-boxs {
        width: 100%;
    }
}
