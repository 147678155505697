div.header {
	background-image: url(../img/bg002.jpg);
	background-position: center center;
	background-size: cover;
	padding-top: 15px;
	z-index: 10;
	position: relative;
}

div.header h1 {
	color: var(--color-text-brightondark);
	width: 100%;
	text-align: center;
}

div.header div.divider-line {
	margin-right: auto;
}


div.trajectory {
	font-weight: 500;
	color: #a9aaad;
}

div.trajectory a {
	display: inline-block;
	padding: 0 12px;
	color: var(--color-inputs-border-focus);
	text-decoration: none;
}

div.trajectory a:hover {
	color: white;
}

div.trajectory span {
	display: inline-block;
	padding: 0 12px;
	color: white;
}
