div.occupations-grid {
	display: grid;
	grid-template-columns: 30% 30% 30%;
	gap: 20px;
	justify-content: center;
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
    margin-bottom: 70px;
}
div.occupations-grid a {
	color: var(--color-brown-darker);
}

div.occupations-grid div.occupation-box {
	background-color: #fcfcfc;
	padding: 30px;
	font-size: 16px;
	line-height: 28px;
	text-align: center;
	box-shadow: 0px 10px 60px 0px rgba(0, 0, 0, 0.1);
}

div.occupations-grid h2 {
    font-size: 20px;
    line-height: 30px;
    font-weight: 600;
    margin-bottom: 0;
}
div.occupations-grid div.occupation-box div.inner {
	transition: transform 0.3s;
}
div.occupations-grid div.occupation-box a.more-info {
	margin-top: 25px;
	display: block;
	opacity: 0;
	transition: transform 0.6s, opacity 0.4s;
}

div.occupations-grid div.occupation-box:hover div.inner {
	transform: translateY(-15px);
}
div.occupations-grid div.occupation-box:hover a.more-info {
	transform: translateY(-15px);
	opacity: 1;
}

div.occupations-grid div.occupation-box div.divider-line {
	height: 2px;
	margin: 20px auto 22px auto;
}

div.occupations-grid div.occupation-box div.divider-space.h3 {
	height: 10px;
}


@media screen and (max-width: 1024px) {
	div.occupations-grid {
		grid-template-columns: 48% 48%;
	}
}
@media screen and (max-width: 700px) {
	div.occupations-grid {
		grid-template-columns: 100%;
	}
	div.occupations-grid div.occupation-box {
		margin: 0 25px 25px 25px;
	}


	div.occupations-grid div.occupation-box div.inner {
		transform: translateY(-15px);
	}
	div.occupations-grid div.occupation-box a.more-info {
		transform: translateY(-15px);
		opacity: 1;
		text-decoration: underline;
	}	
}